import { CreateQueryParams } from "@nestjsx/crud-request";

import { joinPaths } from "../utils";

import { Crud } from "./_crud";
import { apiDelete, apiGet, apiPatch, apiPost } from "./_server";
import {
  AddContentsToFoldersDto,
  Content,
  CreateContentLocal,
  RagChatEvaluationEnum,
  RagContentResponse,
} from "./models/content";
import { BasePaginationResponse } from "./models/pagination";

class ContentCrud extends Crud<Content, any, any> {
  constructor() {
    super({
      path: "content",
      relations: ["language"],
    });
  }

  override async getOne(id?: string | null): Promise<Content> {
    return apiGet({ path: joinPaths(this.path, `${id}`) });
  }

  async getContentsExclusivelyInFolderTree(folderId: string): Promise<Content[]> {
    return apiGet({ path: joinPaths(this.path, "exclusively_in_folder", folderId) });
  }

  async getLatestViewedContents(): Promise<Content[]> {
    return apiGet({ path: joinPaths(this.path, "recently_viewed") });
  }

  async getUserRecentUploads(): Promise<CreateContentLocal[]> {
    return apiGet({ path: joinPaths(this.path, "recent_uploads") });
  }

  async addContentToFolders(id: string, foldersIds: string[]): Promise<void> {
    return apiPatch({ path: joinPaths(this.path, id, "folders"), data: { foldersIds } });
  }

  async addContentsToFolders(data: AddContentsToFoldersDto): Promise<void> {
    await apiPost({ path: joinPaths(this.path, "add_contents_to_folders"), data });
  }

  async hideContent(id: string): Promise<void> {
    return apiPost({ path: joinPaths(this.path, id, "hide") });
  }

  async unhideContent(id: string): Promise<void> {
    return apiPost({ path: joinPaths(this.path, id, "unhide") });
  }

  async removeContentFromLibrary(contentId: string, folderId: string): Promise<void> {
    return apiDelete({ path: joinPaths(this.path, "content_folder", contentId, folderId) });
  }

  async getRecommendedContents(
    query?: CreateQueryParams
  ): Promise<BasePaginationResponse<Content>> {
    const queryString = query ? `?json=${JSON.stringify(query)}` : "";

    return apiGet({ path: "content/recommended" + queryString });
  }

  async ragSearch(
    query: string,
    includeNews: boolean,
    includeMySpace: boolean,
    chatId?: string,
    foldersIds?: string[]
  ): Promise<RagContentResponse> {
    const queryString: { [key: string]: any } = { query, includeNews, includeMySpace };

    if (chatId !== undefined && chatId.length > 0) {
      queryString.chatId = chatId;
    }

    if (foldersIds !== undefined && foldersIds.length > 0) {
      queryString.foldersIds = foldersIds;
    }

    return apiGet({ path: `/content/rag?json=${JSON.stringify(queryString)}` });
  }

  async evaluateChat(chatId: string, evaluation: RagChatEvaluationEnum): Promise<void> {
    return apiPost({ path: `content/rag/evaluate/${chatId}`, data: { evaluation } });
  }

  async reportFeedback(
    chatId: string,
    report: string,
    evaluation: RagChatEvaluationEnum
  ): Promise<void> {
    return apiPost({ path: `content/rag/report_feedback/${chatId}`, data: { report, evaluation } });
  }

  async recordContentDownload(id: string, version?: number): Promise<void> {
    return apiPost({ path: joinPaths(this.path, id, "download"), config: { params: { version } } });
  }

  async moveToTrashBinByIds(ids: string[]): Promise<void> {
    await apiDelete({ path: this.path, data: { ids } });
  }

  async deletePermanentlyByIds(ids: string[]): Promise<void> {
    await apiDelete({ path: joinPaths(this.path, "permanently"), data: { ids } });
  }

  async restoreFromTrashBinByIds(ids: string[]): Promise<void> {
    await apiPatch({ path: joinPaths(this.path, "restore"), data: { ids } });
  }
}

export const contentCrud = new ContentCrud();
